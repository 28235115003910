// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"pT6CTduPL89Pr0KTKGjp_"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/rc_next_web/production";import * as Sentry from "@sentry/nextjs"

// ブラウザ側のSentry初期設定（backendはinstrumentation.ts）
Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  environment:
    process.env.NEXT_PUBLIC_APP_ENV === "production" ||
    process.env.NEXT_PUBLIC_APP_ENV === "staging"
      ? "production"
      : "development",

  // nuxt同様0.001
  tracesSampleRate: 0.001,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // Replayは有効に活用できてないので送らない
  replaysOnErrorSampleRate: 0,
  replaysSessionSampleRate: 0,

  beforeSend(event) {
    if (event.exception?.values) {
      const exception = event.exception.values[0]
      if (exception.stacktrace?.frames) {
        const frames = exception.stacktrace.frames
        const lastFrame = frames[frames.length - 1]
        // Fluctの広告scriptがbodyに埋め込むscriptでエラーが大量に発生するので、その場合はSentryに送らない
        if (lastFrame.function === "showFluctAd") {
          return null
        }
      }
    }
    // その他のエラーは通常通り送信
    return event
  },

  // 広告スクリプトのエラーはSentryに送らない
  denyUrls: [/https?:\/\/(.+\.)?adingo\.jp/],

  ignoreErrors: [
    // 拡張機能などのdeprecatedを示すwarningは圧迫するだけなのでSentryに送らない
    /chrome\.loadTimes/,
    /Synchronous XMLHttpRequest/,
    /ReportingObserver/,
    // 明示的に送りたくないエラーにはignoreSentryLog:をつけるようにしている
    /ignoreSentryLog:/,
    // JSON-LDで<script>直後に[]が来るとMacSafariでエラーを吐くためignore
    `undefined is not an object (evaluating 'r["@context"].toLowerCase')`,
    // iOS Safariとaxiosの問題でリクエストが終わる前に遷移すると発生するらしい。大量に来るのでignore
    "Network Error",
    // おそらく拡張機能によるエラー
    "TypeError: Cannot read properties of undefined (reading 'brackets')",
    // GoogleAdsenseのエラーが大量に発生するのでignore
    /adsbygoogle\.push\(\) error: No slot size for availableWidth=0/
  ]
})
